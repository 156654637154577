import {currentOrientation, isMobile} from "../utils/internal_utils";
import {ORIENTATION} from "../utils/constants";
import React from "react";
import "./OrientationPlaceholder.css";
import phone from '../../assets/phone.png'

const AvailableOrientation = ORIENTATION.PORTRAIT;

export class OrientationPlaceholder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orientation: currentOrientation()
    };
  }

  componentDidMount() {
    window.addEventListener('orientationchange', () => {
      this.setState({
        orientation: this.state.orientation === ORIENTATION.PORTRAIT ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT
      })
      setTimeout(() => {
        this.setState({
          orientation: currentOrientation()
        });
      }, 1000);

    });
  }

  render() {
    let content = null;
    if (this.state.orientation !== AvailableOrientation && isMobile.any()) {
      content = <div className="orientation-placeholder">
        <p className="orientation-text">
          поверни телефон
        </p>
        <img src={phone} alt="phone" className="orientation-phone"/>
      </div>
    }
    return content;
  }


}
